import {
  SET_PROJECT_ID,
  SET_FOLDER_ID,
  SET_CONTEXT,
  SET_LINK_FORMAT,
  SET_IS_INITIALIZED,
  SET_GPRID,
  SET_FOLDER_ACCESS_ORIGIN,
  SET_URLS,
} from './actionConstants';
import { Action } from './types';
import { defaultOptions } from '../services/fetchHelper';
import { Urls } from '../../index';

export const setProjectId = (projectId: string): Action<string> => {
  return {
    type: SET_PROJECT_ID,
    payload: projectId,
  };
};

export const setCurrentFolderId = (folderId: string): Action<string> => {
  return {
    type: SET_FOLDER_ID,
    payload: folderId,
  };
};

export const setCurrentFolderAccessOrigin = (
  accessOrigin: any
): Action<string> => {
  return {
    type: SET_FOLDER_ACCESS_ORIGIN,
    payload: accessOrigin,
  };
};

export const setContext = (context: string): Action<string> => {
  return {
    type: SET_CONTEXT,
    payload: context,
  };
};

export const setUrls = (urls: Urls): Action<Urls> => {
  return {
    type: SET_URLS,
    payload: urls,
  };
};

export const setLinkFormat = (format: string): Action<string> => {
  return {
    type: SET_LINK_FORMAT,
    payload: format,
  };
};

export const setIsInitialized = (value: boolean): Action<boolean> => {
  return {
    type: SET_IS_INITIALIZED,
    payload: value,
  };
};

export const setGPRId = (id: string): Action<string> => {
  return {
    type: SET_GPRID,
    payload: id,
  };
};

export const fetchProjectContext = () => {
  return (dispatch: any, getState: any) => {
    const url = `${
      getState().urls.connectedContextUrl
    }/sv1.0/Repositories/BentleyCONNECT--Main/ConnectedContext/Context/${
      getState().navigation.projectId
    }`;
    console.log(`Started fetching context info `);
    return fetch(url, { ...defaultOptions() })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          dispatch(setContext('Project'));
          return null;
        } else {
          dispatch(
            setContext(
              response != null &&
                response.instances != null &&
                response.instances[0] != null &&
                response.instances[0].properties != null &&
                response.instances[0].properties.ContextTypeId == 2
                ? 'Asset'
                : 'Project'
            )
          );
          console.log('Successfully context info');
          return response.instances[0].properties;
        }
      })
      .catch(err => {
        console.error(`Failed to context info: ${err}`, [err]);
        throw err;
      });
  };
};
