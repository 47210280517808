import { toastType, toast } from '../services/toastService';
import { invalidateData } from './dataActions';
import { getProjectShareBaseUrl } from '../services/utillities';
import { defaultShareOptions } from '../services/fetchHelper';
import { Item } from '../entities/entities';

export const move = (
  itemsToMove: Item[],
  newParentId: string,
  oldParentId: string
) => {
  return (dispatch: any) => {
    const url = `${getProjectShareBaseUrl()}/$changeset`;
    const body = JSON.stringify(data(itemsToMove, newParentId));
    console.log(`Started move item operation`);
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          console.error(`Failed to move item: ${response.errorMessage}`, [
            response.errorMessage,
          ]);
          toast(toastType.error, 'Move Item Failure');
        } else {
          dispatch(invalidateData());
          if (document.getElementsByClassName('rt-tbody')[0]) {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }
          const options = {
            link: {
              url: () =>
                dispatch(
                  move(
                    response.changedInstances.map(
                      (instance: any) => instance.instanceAfterChange
                    ),
                    oldParentId,
                    response.changedInstances[0].instanceAfterChange.properties
                      .ParentFolderId
                  )
                ),
              title: 'Move undo',
              'data-testid': 'MoveUndo',
            },
          };
          console.log('Moved item successfully');
          itemsToMove.length == 1
            ? toast(toastType.success, 'Move Item', options)
            : toast(toastType.success, 'Move Items', options);
        }
      });
  };
};

const data = (itemsToMove: Item[], newParentId: string) => {
  const instances = itemsToMove.map(item => {
    return {
      instanceId: item.instanceId,
      schemaName: 'ProjectShare',
      className: item.className,
      changeState: 'modified',
    };
  });
  return {
    instances: instances,
    requestOptions: {
      CustomOptions: {
        IsMoved: true,
        ParentFolderId: newParentId,
      },
    },
  };
};
