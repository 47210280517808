import {
  SET_AUDIT_ITEM,
  SET_AUDIT_LOADING,
  SET_AUDIT_DATA,
  SET_AUDIT_ERROR,
} from './actionConstants';

import { Action } from './types';
import {
  IAuditRecord,
  IAuditValue,
} from '../components/auditTrail/auditRecord';

import { defaultOptions } from '../services/fetchHelper';
import { getProjectShareUrl } from '../services/utillities';
import i18n from '../i18n';

export const actionMap = {
  create: 'AuditTrail action Created',
  update: 'AuditTrail action Updated',
  hardDelete: 'AuditTrail action Hard deleted',
  moveToRecycleBin: 'AuditTrail action Recycled',
  restore: 'AuditTrail action Restored',
  move: 'AuditTrail action Moved',
  lock: 'AuditTrail action folder Locked',
  unlock: 'AuditTrail action folder Unlocked',
  fileLock: 'AuditTrail action file Locked',
  fileUnlock: 'AuditTrail action file Unlocked',
  secure: 'AuditTrail action Security updated',
  unsecure: 'AuditTrail action Security updated',
  versionPromote: 'File version Promoted',
  blobupsert: 'File content Updated',
};

export const updateData = (
  loadedRecords: IAuditRecord[],
  haveMoreData: boolean
): Action<{ loadedRecords: IAuditRecord[]; haveMoreData: boolean }> => {
  return {
    type: SET_AUDIT_DATA,
    payload: { loadedRecords, haveMoreData },
  };
};

export const setIsLoading = (isLoading: boolean): Action<boolean> => {
  return {
    type: SET_AUDIT_LOADING,
    payload: isLoading,
  };
};

export const setError = (error: string): Action<string> => {
  return {
    type: SET_AUDIT_ERROR,
    payload: error,
  };
};

export const setItem = (item: any): Action<any> => {
  return {
    type: SET_AUDIT_ITEM,
    payload: item,
  };
};

export const fetchAuditData = (
  top: number = 0
): ((dispatch: any, getState: any) => Promise<void>) => {
  return (dispatch: any, getState: any) => {
    const item = getState().audit.currentItem;
    if (!(item && item.instanceId)) {
      return Promise.resolve();
    }
    const url = getAuditItemUrl(item, top);
    dispatch(setIsLoading(true));
    console.log(`Started fetching AuditTrail data`);
    return fetch(url, { ...defaultOptions(), method: 'GET' })
      .then(response => response.json())
      .then(json => {
        if (json.errorMessage) {
          dispatch(setError(json.errorMessage));
          console.error(
            `Failed to fetch AuditTrail data: ${json.errorMessage}`,
            [json.errorMessage]
          );
          return;
        }
        const auditRecords = json.instances
          .map(mapInstanceToAuditRecord)
          .filter((instance: any) => {
            return instance.recordBy != null;
          });
        dispatch(
          updateData(auditRecords, top > 0 && auditRecords.length === top)
        );
      })
      .catch(error => {
        dispatch(setError(error.message));
        console.error(`Failed to fetch AuditTrail data: ${error}`, [error]);
      });
  };
};

export function getAuditItemUrl(item: any, top: number = 0): string {
  const filter = `$filter=RelatedInstanceId+eq+'${item.instanceId}'`;
  let range = '';
  if (top != 0) {
    range = `$top=${top}&`;
  }

  return getProjectShareUrl() + '/AuditRecord/?' + range + filter;
}

export function getEventType(instance: any): string {
  const data = JSON.parse(instance.properties.Data);
  const type = instance.properties.EventType.split('.').pop();

  const lockedBefore =
    data && data.propertiesBeforeEvent
      ? data.propertiesBeforeEvent.isLocked
      : undefined;
  const lockedAfter =
    data && data.propertiesAfterEvent
      ? data.propertiesAfterEvent.isLocked
      : undefined;

  if (
    type == 'update' &&
    data.messageDetails.instanceType == 'File' &&
    lockedAfter != null &&
    lockedBefore != lockedAfter
  ) {
    return lockedAfter ? 'fileLock' : 'fileUnlock';
  } else {
    return type;
  }
}

export function mapInstanceToAuditRecord(instance: any): IAuditRecord {
  const data = JSON.parse(instance.properties.Data);

  const userName =
    data && data.messageDetails && data.messageDetails.actionPerformedByName
      ? data.messageDetails.actionPerformedByName
      : null;

  //change values
  const changeProperties: IAuditValue[] = [];

  if (data && data.propertiesBeforeEvent && data.propertiesAfterEvent) {
    for (var key in data.propertiesBeforeEvent) {
      if (
        key in data.propertiesAfterEvent &&
        data.propertiesAfterEvent[key] !== data.propertiesBeforeEvent[key]
      ) {
        const changeValue = {
          property: key,
          oldValue: data.propertiesBeforeEvent[key],
          newValue: data.propertiesAfterEvent[key],
        };
        if (changeValue.property === 'path') {
          changeValue.oldValue = removeProjectPath(changeValue.oldValue);
          changeValue.newValue = removeProjectPath(changeValue.newValue);
        }

        changeProperties.push(changeValue);
      }
    }
  }

  const auditRecord = {
    recordBy: userName,
    recordDate: instance.properties.EventTime,
    //@ts-ignore
    action: i18n.t(actionMap[getEventType(instance)]),
    values: changeProperties,
  };

  function removeProjectPath(path: string): string {
    return path
      .split('/')
      .slice(1)
      .join('/');
  }

  return auditRecord;
}
