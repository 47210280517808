@import '../icons/variables';
@import '../style/index';
@import '../text/mixins';

@mixin bwc-user-icons-icon($size: $bwc-icons-medium) {
  display: inline-block;
  position: relative;
  width: $size;
  height: 1px;
  margin: 0;

  vertical-align: middle;

  > span {
    @include bwc-text(leading);

    display: block;
    position: absolute;

    z-index: 0;
    top: -$size * 0.5;
    width: $size;
    height: $size;
    line-height: $size;
    margin: 0;
    border-radius: 50%;

    text-align: center;
    font-weight: $bwc-font-weight-semibold;
    text-decoration: none;
    background: $bwc-dataviz-green;
    color: $bwc-white;
  }

  > img {
    display: block;
    position: absolute;

    z-index: 1;
    top: -$size * 0.5;
    width: $size;
    height: $size;

    border-width: 0;
    border-radius: 50%;
  }
}
