@import '../style/index';

@function bwc-tables-padding(
  $padding: $bwc-m,
  $content-size: $bwc-line-height
) {
  @return $padding - ($content-size - $bwc-cap-size) * 0.5;
}

@function bwc-tables-size($padding: $bwc-m) {
  @return 2 * $padding + $bwc-cap-size;
}
