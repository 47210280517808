import { Item } from '../entities/entities';
import { defaultShareOptions } from '../services/fetchHelper';
import { getProjectShareBaseUrl } from '../services/utillities';
import { invalidateData } from './dataActions';
import { toast, toastType } from '../services/toastService';

export const lockItem = (items: Item[], lockStatus: boolean) => {
  return (dispatch: any) => {
    const body = JSON.stringify(lockData(items, lockStatus));
    const url = `${getProjectShareBaseUrl()}/$changeset`;
    console.log(`Started item lock`);
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then((response: any) => {
        if (items.length === 1) {
          if (response.errorMessage) {
            console.error(`Failed to lock: ${response.errorMessage}`, [
              response.errorMessage,
            ]);
            items[0].className == 'Folder'
              ? lockStatus
                ? toast(toastType.error, 'Lock Files Failure')
                : toast(toastType.error, 'Unlock Files Failure')
              : lockStatus
              ? toast(toastType.error, 'Lock File Failure')
              : toast(toastType.error, 'Unlock File Failure');
          } else {
            console.log('Successfully locked');
            items[0].className == 'Folder'
              ? lockStatus
                ? toast(toastType.success, 'Lock Files success')
                : toast(toastType.success, 'Unlock Files success')
              : lockStatus
              ? toast(toastType.success, 'Lock File success')
              : toast(toastType.success, 'Unlock File success');
          }
        } else {
          if (response.errorMessage) {
            console.error(`Failed to lock: ${response.errorMessage}`, [
              response.errorMessage,
            ]);
            lockStatus
              ? toast(toastType.success, 'Lock Files Failure')
              : toast(toastType.success, 'Unlock Files Failure');
          } else {
            console.log('Successfully locked');
            lockStatus
              ? toast(toastType.success, 'Lock Files success')
              : toast(toastType.success, 'Unlock Files success');
          }
        }

        dispatch(invalidateData());
        if (document.getElementsByClassName('rt-tbody')[0]) {
          document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
        }
      });
  };
};

const lockData = (items: Item[], newLock: boolean) => {
  const instances = items.map(item => {
    if (item.className == 'Folder') {
      return {
        instanceId: item.instanceId,
        schemaName: 'ProjectShare',
        className: item.className,
        changeState: 'modified',
      };
    } else {
      return {
        instanceId: item.instanceId,
        schemaName: 'ProjectShare',
        className: item.className,
        properties: {
          Locked: newLock,
        },
        relationshipInstances: [],
        changeState: 'modified',
      };
    }
  });
  return items[0].className == 'File'
    ? { instances: instances }
    : {
        instances: instances,
        requestOptions: {
          CustomOptions: {
            AreFilesLocked: newLock,
          },
        },
      };
};
