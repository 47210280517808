@import '../style/index';

$bwc-inputs-height: 38px;
$bwc-inputs-height-large: 48px;

$bwc-inputs-padding: ($bwc-inputs-height - $bwc-line-height) * 0.5;
$bwc-inputs-padding-large: ($bwc-inputs-height-large - $bwc-line-height) * 0.5;
$bwc-inputs-padding-bordered: $bwc-inputs-padding - 1px;
$bwc-inputs-padding-bordered-large: $bwc-inputs-padding-large - 1px;

$bwc-inputs-margin: ($bwc-line-height * 2 - $bwc-inputs-height) * 0.5;
$bwc-inputs-margin-large: ($bwc-line-height * 3 - $bwc-inputs-height-large) * 0.5;

$bwc-inputs-border-color: $bwc-gray-9;
