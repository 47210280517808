import { Item } from '../entities/entities';
import {
  containsFolder,
  isFile,
  isFolder,
} from '../services/permissionsService';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getProjectShareUrl } from '../services/utillities';
import { getFolderUrl, mapToItem } from './dataActions';
import { defaultShareOptions } from '../services/fetchHelper';
import { toast, toastType } from '../services/toastService';
import {
  MAX_ZIP_DOWNLOAD_FILE_SIZE,
  DEFAULT_PAGINATION_PAGE_SIZE,
} from '../constants';
import sumBy from 'lodash/sumBy';

export const tryDownloadFiles = async (items: Item[]) => {
  let allItems: Item[] = [];

  for (const item of items) {
    if (isFile(item)) {
      allItems.push(item);
    } else {
      const result = await getItemsInFolder(item);
      allItems = allItems.concat(result);
    }

    if (areFilesTooLargeForDownload(allItems)) {
      console.warn('Files Too Big For Zip');
      toast(toastType.error, 'Files Too Big For Zip');
      return;
    }
  }

  if (allItems.length > 1) {
    toast(toastType.information, 'Preparing Items For Download');
  }
  downloadFiles(items);
};

export const areFilesTooLargeForDownload = (items: Item[]) => {
  const sizeSum = sumBy(items, x => Number(x.size));
  return items.length !== 1 && sizeSum > MAX_ZIP_DOWNLOAD_FILE_SIZE;
};

export const downloadFiles = async (items: Item[]) => {
  if (items.length === 1 && !containsFolder(items)) {
    openDownloadDialog(items[0]);
    return;
  }

  const zip = new JSZip();
  await addBlobsToZip('', items, zip);
  zip
    .generateAsync({ type: 'blob', compression: 'DEFLATE' })
    .then((content: any) => {
      console.log('Successfully saved zip');
      saveAs(content, 'ShareFiles.zip');
    })
    .catch((err: any) => {
      console.error(`Zip generation failed: ${err}`, [err]);
      toast(toastType.error, 'Zip generation failed');
    });
};

const addBlobsToZip: (
  folderName: string,
  items: Item[],
  zip: JSZip
) => Promise<void> = async (folderName: string, items: Item[], zip: JSZip) => {
  if (items.length === 0) {
    return;
  }
  const files = items.filter(isFile);
  const folders = items.filter(isFolder);
  const blobs = await Promise.all(
    files.map(file => fetch(file.accessUrl).then(res => res.blob()))
  );
  const currDate = new Date();
  const dateWithOffset = new Date(
    currDate.getTime() - currDate.getTimezoneOffset() * 60000
  );

  blobs.forEach((blob, ind) => {
    zip.file(folderName + files[ind].name, blob, { date: dateWithOffset });
  });
  if (folderName !== '') {
    // limit recursion depth
    return;
  }

  for (const folder of folders) {
    zip.folder(folderName + folder.name);
    const itemsInFolder = await getItemsInFolder(folder);
    await addBlobsToZip(folderName + folder.name + '/', itemsInFolder, zip);
  }
  return;
};

const getItemsInFolder = async (item: Item): Promise<Item[]> => {
  const subfoldersPromises = fetchAllItemsInFolder(item);

  const result: any = await subfoldersPromises;
  const subfolderFiles = result.reduce((a: any, b: any) => a.concat(b), []);
  return [...subfolderFiles];
};

const fetchAllItemsInFolder = async (item: Item): Promise<Item[]> => {
  let page = 0;
  let allItems: Item[] = [];
  return new Promise((resolve, reject) => {
    const getAllItems = async () => {
      getItemsInFolderByPage(item, page).then(items => {
        allItems = allItems.concat(items);

        if (areFilesTooLargeForDownload(allItems)) {
          console.warn('Files Too Big For Zip');
          toast(toastType.error, 'Files Too Big For Zip');
          return resolve([]);
        }
        if (items.length === DEFAULT_PAGINATION_PAGE_SIZE) {
          page = page + 1;
          getAllItems();
        } else {
          resolve(allItems);
        }
      });
    };
    getAllItems();
  });
};

const getItemsInFolderByPage = (folder: Item, page?: number) => {
  const baseUrl = getProjectShareUrl();
  const url = `${baseUrl}/${getFolderUrl(folder.instanceId, false, page)}`;
  console.log(`Started fetching data for folder`);
  return fetch(url, { ...defaultShareOptions() })
    .then(response => response.json())
    .then(json => {
      if (json.errorMessage) {
        console.error(`Failed to fetch data: ${json.errorMessage}`, [
          json.errorMessage,
        ]);
        toast(toastType.error, `Get content for folder ${folder.name} failed`);
        return [];
      } else {
        console.log('Successfully fetched data for folder');
        return mapToItem(json.instances);
      }
    })
    .catch(error => {
      console.error(`Failed to fetch data for folder: ${error}`, [error]);
      toast(toastType.error, `Get content for folder ${folder.name} failed`);
      return [];
    });
};

function openDownloadDialog(item: Item) {
  window.open(item.accessUrl, '_self');
}
