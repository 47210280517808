import { RbacProjectPermissions } from '../entities/entities';
import { Action } from './types';
import { SET_RBAC_PROJECT_PERMISSIONS } from './actionConstants';
import { defaultOptions } from '../services/fetchHelper';

const getRbacUrl = (
  baseUrl: string,
  projectId: string,
  context: string,
  userId: string
): string =>
  `${baseUrl}/v2.4/Repositories/BentleyCONNECT--Main/RBAC/User/${userId}/${context}?$filter=$id eq '${projectId}' and Permission.ServiceGPRId eq 2567&$select=*,Permission.*`;

export const setProjectPermissions = (
  permissions: RbacProjectPermissions
): Action<RbacProjectPermissions> => {
  return { type: SET_RBAC_PROJECT_PERMISSIONS, payload: permissions };
};

export const fetchProjectPermissions = () => {
  return (dispatch: any, getState: any) => {
    const url = getRbacUrl(
      getState().urls.rbacUrl,
      getState().navigation.projectId,
      getState().navigation.context,
      getState().user.id
    );
    console.log(`Started fetching project permissions`);
    return fetch(url, { ...defaultOptions() })
      .then(response => response.json())
      .then(response => {
        const instances = response.instances[0].relationshipInstances;
        const permissions = instances.map(
          (p: any) => p.relatedInstance.instanceId
        );
        const rbacPermissions: RbacProjectPermissions = {
          rbacRead:
            permissions.findIndex((p: any) => p === 'SHARE_READ') !== -1 ||
            permissions.findIndex((p: any) => p === 'SHARE_WRITE') !== -1 ||
            permissions.findIndex((p: any) => p === 'SHARE_DELETE') !== -1,
          rbacWrite:
            permissions.findIndex((p: any) => p === 'SHARE_WRITE') !== -1 ||
            permissions.findIndex((p: any) => p === 'SHARE_DELETE') !== -1,
          rbacDelete:
            permissions.findIndex((p: any) => p === 'SHARE_DELETE') !== -1,
          rbacAllowInstanceSecurity:
            permissions.findIndex((p: any) => p === 'SECUREFOLDER') !== -1,
        };
        dispatch(setProjectPermissions(rbacPermissions));
        console.log('Fetched project permissions successfully');
        return rbacPermissions;
      })
      .catch(error => {
        console.error(`Failed to fetch project permissions: ${error}`, [
          error,
        ]);
      });
  };
};
