import { getProjectShareUrl } from './utillities';
import { defaultShareOptions } from './fetchHelper';

export const createFolderRequest = (
  folderName: string,
  parentInstanceId: string
) => {
  const body = JSON.stringify(data(folderName, parentInstanceId));
  const url = `${getProjectShareUrl()}/Folder`;
  console.log(`Started create folder`);
  return fetch(url, {
    ...defaultShareOptions(),
    method: 'POST',
    body: body,
  }).then(response => response.json());
};

const data = (folderName: string, parentInstanceId: string) => {
  return {
    instance: {
      schemaName: 'ProjectShare',
      className: 'Folder',
      properties: {
        Name: folderName,
        ContentType: 'Folder',
      },
      relationshipInstances: [
        {
          schemaName: 'ProjectShare',
          className: 'FolderHasContent',
          direction: 'backward',
          relatedInstance: {
            schemaName: 'ProjectShare',
            className: 'Folder',
            instanceId: parentInstanceId,
            changeState: 'existing',
          },
        },
      ],
    },
  };
};

export const createRootFolder = (projectId: any) => {
  var data = {
    instance: {
      schemaName: 'ProjectShare',
      className: 'Folder',
      instanceId: projectId,
      changeState: 'New',
      properties: {
        RootFolder: true,
      },
    },
  };
  var rootFolderUrl = getProjectShareUrl() + '/Folder/';
  return fetch(rootFolderUrl, {
    ...defaultShareOptions(),
    method: 'POST',
    body: JSON.stringify(data),
  });
};
