import { defaultOptions } from '../services/fetchHelper';
import { Item } from '../entities/entities';
import { toast, toastType } from '../services/toastService';
import { postToImodelHubRequest } from '../services/postToImodelHubService';

export const getRedirectUrl = (
  selectedItems: Item[],
  currentFolderId: string,
  projectId: string,
  domainUrl: string,
  linkFormat: string
) => {
  let link = linkFormat.replace('{projectId}', projectId);
  link = link.replace('{folderId}', currentFolderId);
  link = link.replace('{itemId}', 'selection');
  sessionStorage.setItem('selection', JSON.stringify(selectedItems));
  return domainUrl + (domainUrl.endsWith('/') ? '' : '/') + link;
};

export const authenticateUser = (
  selectedItems: Item[],
  currentFolderId: string,
  projectId: string,
  domainUrl: string,
  linkFormat: string
) => {
  const headers = <any>{};
  return (dispatch: any, getState: any) => {
    const inputfiles = selectedItems.map(item => item.instanceId);
    const orchConverstionServiceUrl = getState().urls.orchConversionServiceUrl;
    headers.UserId = getState().user.id;
    headers.RedirectUrl = getRedirectUrl(
      selectedItems,
      currentFolderId,
      projectId,
      domainUrl,
      linkFormat
    );
    const url = `${orchConverstionServiceUrl}/api/AuthenticateUser`;
    console.log(`Started posting to imodel`);
    return fetch(url, { ...defaultOptions(), method: 'POST', headers })
      .then(response => response.json())
      .then(response => {
        if (response == 'User id valid.') {
          return postToImodelHubRequest(inputfiles).catch(() => {
            console.error('Post Imodel Failed');
            toast(
              toastType.error,
              selectedItems.length == 1
                ? 'Post Imodel Failed'
                : 'Post Imodels Failed'
            );
          });
        } else {
          console.log('Post Imodel Login First');
          toast(toastType.information, 'Post Imodel Login First');
          return (window.location.href = response);
        }
      })
      .catch(error => {
        console.error(`Failed to authenticate: ${error}`, [error]);
        toast(toastType.error, 'Failed to authenticate.');
      });
  };
};
